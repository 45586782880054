export const environment = {
  production: true,
  buildPipeline: 389867,
  buildDate: "2024-11-22T07:11:46+00:00",
  branch: '',
  httpRestUrlPrefix: 'bg',
  wss: [
    {
      name: 'rest',
      url: `${window.location.protocol === 'http:' ? 'ws://' : 'wss://'}${window.location.host}/bg`,
    },
  ],
  sfsDomainUrl: '',
  wsRestUrl: `${window.location.protocol === 'http:' ? 'ws://' : 'wss://'}${window.location.host}/bg`,
  gasterUrl: `${window.location.protocol === 'http:' ? 'ws://' : 'wss://'}${window.location.host}/gaster/ws`,
  nfias: '',
  UrlNFias: undefined,
  region: '40',
  app: 1,
  appName: 'prod-klg',
  notification: {
    timer: 3000,
    infoBufferMax: 10,
    errorBufferMax: 50,
    infoStackLength: 3,
    errorStackLength: 5,
    stackPosition: 'bottom-right',
  },
};
